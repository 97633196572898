$templateSize: 30vw;
$templateMaxSize: 80vw;
$templateMobileSize: 95vw;
$chartSize: 60vw;
$chartMobileSize: $templateMobileSize;
$templateMarginTop: calc($templateSize / 4);
$templateMarginRight: calc($templateSize / 11.11111111111111);
$templateMarginBottom: calc($templateSize / 5);
$templateMarginLeft: $templateMarginRight;

$iconSize: 20vw;
$iconFontSize: calc($iconSize / 2);
$iconLabel: calc($iconSize / 8.333333333333334);
$iconMarginTop: calc($iconSize / 4);
$iconMarginRight: calc($iconSize / 11.11111111111111);
$iconMarginBottom: calc($iconSize / 10);
$iconMarginLeft: $iconMarginRight;

$containerMarginTop: calc($iconSize / 20);
$containerMarginRight: calc($iconSize / 6.6666666666666666);
$containerMarginBottom: calc($iconSize / 6.6666666666666666);
$containerMarginLeft: calc($iconSize / 20);
$containerBorderRadius: 3vw;

$borderRadius: 3vw;

$desktopScaling: 2;

$color: #282c34;
$darkColor: #191b1d;
