@import "_mixins.scss";
@import "_variables.scss";

* {
  box-sizing: border-box;
}

.App {
  background-color: $color;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  color: white;
  padding: 4vw 2vw;
  box-sizing: border-box;
  font-size: calc(10px + 2vmin);

  a {
    text-decoration: none;
  }

  .PTR {
    min-height: 100vh;
  }

  .titles {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
    font-size: 1em;
    margin-bottom: 1vw;

    h1,
    .active {
      font-size: 1.2em;
      margin: 0 10px 0;
      color: white;
      pointer-events: none;
      font-weight: bold;
    }
    a {
      font-size: 0.8em;
      margin: 0 20px 0;
      color: white;
    }
  }

  .Apps {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    align-content: flex-start;
    justify-content: left;
    font-size: 1em;

    @media screen and (min-width: 1000px) {
      // transform-origin: top center;
      // transform: scale(0.5);
    }

    .Group {
      display: flex;
      flex-direction: column;
      background: #ffffff44;
      border-radius: $containerBorderRadius;
      margin-top: 0;
      margin-bottom: $containerMarginBottom;

      &:not([groupid]) {
        background-color: transparent;
      }

      @media screen and (min-width: 1000px) {
        margin-right: calc(#{$containerMarginRight} / #{$desktopScaling});
        margin-bottom: calc(#{$containerMarginBottom} / #{$desktopScaling});
        border-radius: calc(#{$containerBorderRadius} / #{$desktopScaling});
      }

      .GroupName {
        font-size: $iconLabel;
        margin: $containerMarginTop $containerMarginRight 0;
        line-height: $iconLabel;

        @media screen and (min-width: 1000px) {
          font-size: calc(#{$iconLabel} / #{$desktopScaling});
          line-height: calc(#{$iconLabel} / #{$desktopScaling});
          margin: calc(#{$containerMarginTop} / #{$desktopScaling})
            calc(#{$containerMarginRight} / #{$desktopScaling}) 0;
        }
      }

      .GroupItems {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        max-width: 94.4vw;

        @media screen and (min-width: 1000px) {
          flex-direction: column;
          max-height: 70vh;
        }

        & > a {
          position: relative;
          overflow: hidden;

          & > div {
            margin-top: calc(#{$containerMarginTop} / 2);

            &.IconChart,
            &.IconMap {
              position: absolute;
              width: $iconSize !important;
              height: $iconSize !important;
              top: 0;
              left: 50%;
              transform: translateX(-50%);
              overflow: hidden;
              border-radius: $borderRadius;

              & > :global(.recharts-responsive-container) {
                opacity: 0.3;
              }

              // @include bounce-out();

              & + div > div {
                background: none !important;
              }

              @media screen and (min-width: 1000px) {
                width: calc(#{$iconSize} / #{$desktopScaling}) !important;
                height: calc(#{$iconSize} / #{$desktopScaling}) !important;
                border-radius: calc(#{$borderRadius} / #{$desktopScaling});
              }

              :global(.leaflet-container) {
                width: 100%;
                height: 100%;

                :global(.leaflet-control-container) {
                  display: none;
                }
              }
            }

            @media screen and (min-width: 1000px) {
              margin-bottom: calc(
                #{$containerMarginTop} / 2 / #{$desktopScaling}
              );
            }
          }
        }
      }

      .GroupName + .GroupItems {
        & > a > div {
          margin-top: $containerMarginTop;
          margin-bottom: calc(#{$containerMarginTop} / 1);

          @media screen and (min-width: 1000px) {
            margin-bottom: calc(
              #{$containerMarginTop} / 1 / #{$desktopScaling}
            );
          }
        }
      }
    }
  }

  .Action {
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    align-content: center;
    justify-content: center;

    h2 {
      text-align: center;
      span {
        font-weight: normal;
      }
    }

    .templates {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      margin: $templateMarginTop $templateMarginRight;
      min-width: $templateMobileSize;
      max-width: $templateMobileSize;

      @media screen and (min-width: 1000px) {
        min-width: $templateSize;
        max-width: $templateMaxSize;
      }

      .templateWrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        border-left: 1px solid white;
        background-color: $color;

        &:last-child {
          border-bottom: 1px solid white;
        }

        & > .templateWrapper {
          margin-left: 20px;
          width: calc(100% - 20px);

          &:last-child {
            border-bottom: none;
          }
        }

        // .templateIndent {
        //   width: 20px;
        //   @include indentTemplate();
        // }

        .template {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          border-top: 1px solid white;
          padding: 10px;

          &.map {
            padding: 0;
            cursor: pointer;

            .fullScreenMap {
              position: fixed;
              width: 100vw;
              height: 100vh;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              z-index: 1000;
            }
            .hidden {
              display: none;
            }
          }

          &.childTemplate {
            // @include childTemplates();
          }

          .label {
            font-weight: bold;
            margin-right: 10px;
          }

          .value {
            word-break: break-all;
          }

          .appendix {
            font-style: italic;
          }
        }

        &.dark {
          background-color: $darkColor;
        }
      }
    }

    .chart {
      width: $chartMobileSize;
      height: 50vw;

      @media screen and (min-width: 1000px) {
        width: $chartSize;
        height: 30vw;
      }
    }

    .buttons {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 50px;

      a {
        color: white;
        margin: 10px;
      }

      button {
        background: white;
        color: $color;
        border: none;
        padding: 20px;
        font-size: 1.4em;
        font-weight: bold;
        border-radius: 30px;
        cursor: pointer;
        margin: 10px;
        @include keyframes("pulsing", "#FFFFFF");
        animation: pulsing 2s infinite;

        &:hover {
          opacity: 1;
        }
      }

      &.integrated {
        display: flex; 
        flex-wrap: wrap;
        margin-top: 10px;

        button {
          height: calc(100% - 20px);
          font-size: 0.8em;
          border-radius: 20px;
        }

      }
    }
  }
}

.fullScreen {
  overflow: hidden;

  :global(.ptr),
  :global(.ptr__children) {
    position: static !important;
  }

  .App {
    padding: 0;
  }
}
