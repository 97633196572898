@import "../_mixins.scss";
@import "../_variables.scss";

.Container {
  position: relative;
  width: 100%;
  min-width: 60vw;
  height: 300px;

  :global(.leaflet-disabled) {
    display: none;
  }

  :global(.leaflet-routing-container) {
    display: none;
    pointer-events: none;
  }

  &.FullScreen {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 1000;
  }

  & > :global(.leaflet-container) {
    width: 100%;
    height: 100%;

    :global(.leaflet-bar) {
      .button {
        width: auto;
        padding: 0 10px;
      }
      .closeButton {
        width: auto;
        padding: 0 10px;
      }
      .snapButton {
        width: auto;
        padding: 0 10px;
      }
      .toggleRouteButton {
        width: auto;
        padding: 0 10px;
      }
    }
  }

  .marker {
    @keyframes bounce {
      0%,
      20%,
      50%,
      80%,
      100% {
        top: 0;
      }
      40% {
        top: -30px;
      }
      60% {
        top: -15px;
      }
    }

    .markerIcon {
      min-width: 32px;
      width: 100%;
    }

    .markerLabel {
      align-items: center;
      display: flex;
      justify-content: center;
      font-size: 10px;
      box-shadow: 0 3px 14px #00000066;
      color: #333;
      background-color: white;
      position: absolute;
      padding: 2px;
      width: 14px;
      height: 14px;
      top: 5px;
      left: 50%;
      border-radius: 12px;
      transform: translateX(-50%);
    }

    @include zIndexes();

    &.markerLast {
      top: 0;
      left: 0;
      width: 32px;
      height: 32px;
      animation-name: bounce;
      animation-timing-function: ease;
      animation-duration: 3s;
      animation-iteration-count: infinite;
      transform-origin: 50%;
      transform: translateY(0);

      .markerLabel {
        width: 17.5px;
        height: 17.5px;
        font-size: 12px;
      }
    }
  }

  .markerPopupTitle {
    font-weight: bold;
  }
}
